import {Helmet} from "react-helmet";

const SushmithaInvite = () => {
  return (
    <div className="digital-invite-container">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Jayasaikrishna weds Sushmitha Wedding Invitation</title>
                <link rel="canonical" href="https://a2zweddinginfo.com/Jayasaikrishna-weds-Sushmitha" />
                <meta name="description" content="we request the pleasure of your presence with family &amp; friends on the auspicious occasion of the Jaya SaiKrishna &amp; Sushmitha's marriage."></meta>
      </Helmet>
      <div className="overview_card total_normal">
       <h3 className="wedding-invite-name">DABBARA WEDDING INVITATION</h3>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="front pic" src="/invites_img/sushmitha/JS1.JPEG" />
            <img className="flip-img2" alt="card back pic" src="/invites_img/sushmitha/JS2.JPEG" />
          </div>
          <div className="details">
            <img alt="save the date" width={200} src="/invites_img/save-the-date.png" />
            <h1 className="wedding-date">17 <i>DEC</i> 2023 </h1>
            <a href="https://dabbara-wedding-invitation.netlify.app/">
              <img width={50} alt="click" src="/wedding-pics/click-here.PNG" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SushmithaInvite