import {Helmet} from "react-helmet";
const GunaInvitation = () => {
  return (
    <div className="digital-invite-container">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Gunasekhar Raju weds Haritha Wedding Invitation</title>
                <link rel="icon" href="/invites_img/guna/open-card-top.png" />
                <link rel="apple-touch-icon" href="/invites_img/guna/open-card-top.png" />
                <link rel="canonical" href="https://a2zweddinginfo.com/gunasekhar_raju-weds-haritha" />
                <meta name="description" content="You are cordially invited to celebrate the union of Haritha and Gunasekhar Raju . Join us on 16-November-2024 at 7 PM for a ceremony of love and commitment, followed by a reception at VSR wedding hall, Tirupati road, PILERU."></meta>
      </Helmet>
      <div className="overview_card total_normal">
       <h3 className="wedding-invite-name"> CHIKKILI VARI WEDDING INVITATION</h3>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="guna shekhar raju haritha pic" src="/invites_img/guna/open-card-top.png" />
            <img className="flip-img2" alt="guna shekhar raju haritha" src="/invites_img/guna/open-card-left.png" />
          </div>
          <div className="details">
            <img alt="save the date" width={200} src="/invites_img/save-the-date.png" />
            <h3 className="wedding-date"> 16  <i>November</i> 2024 </h3>
            <a href="https://gunasekharraju-weds-haritha.netlify.app/">
             <button className="blink-image invite-btn">Open invite</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GunaInvitation
