import { Card } from "../../Shared/Card/card"
import VenueCard from "./card";
import './wedding-halls.scss'

const WeddingHalls = () => {

  const venues = [
    {
      name: "RPR Convention Center",
      location: "Tirupati",
      price: "On Request",
      rating: 4.5,
      reviews: "No reviews yet",
      parking: 25,
      capacity: 400,
      image: "/img/wedding-halls/rpr.jpg", // Replace with actual image paths
    },
    {
      name: "Gubba Choultry CONVENTION",
      location: "Tirupati",
      price: "On Request",
      rating: 4.5,
      reviews: "No reviews yet",
      parking: 70,
      capacity: 240,
      image: "/img/wedding-halls/gckm.jpg",
    },
    {
      name: "SHREE CONVENTION",
      location: "Tirupati",
      price: "On Request",
      rating: 4.5,
      reviews: "No reviews yet",
      parking: 25,
      capacity: 400,
      image: "/img/wedding-halls/shree_c_h.webp", // Replace with actual image paths
    },
    {
      name: "DBR Convention Hall",
      location: "Tirupati",
      price: "On Request",
      rating: 4.5,
      reviews: "No reviews yet",
      parking: 70,
      capacity: 240,
      image: "/img/wedding-halls/dbr_c_h.avif",
    },
    {
      name: "KK Function Hall",
      location: "Tirupati",
      price: "On Request",
      rating: 4.5,
      reviews: "No reviews yet",
      parking: 75,
      capacity: 400,
      image: "/img/wedding-halls/kk_f_h.webp",
    },
  ];

   return(
    <div className="m400_container">
        <div className='m400 header_container'>
        {/* <CommonHeader title={'Wedding Halls'} /> */}

        <div className='desktop_view_btns'>
          <div className="m400 container_top_btns">
          </div>
        </div>
        <Card>
        {/* <div className="venue_list">
            {venuesData.map((sVenue,i)=>{
                return(
                    <div key={i} className="overview_card total_normal">
                    <div className="overviewTitle">
                      <span className='venue_title'>{sVenue.venue_name}</span>
                    </div>
                    <img className="venue_avatar" src={sVenue.venue_image} alt={sVenue.venue_image} />
                      <span className=''><img width={15} alt="location_logo" className="location_logo" src="https://toppng.com/uploads/thumbnail/white-location-icon-png-location-logo-png-white-11562856661b4wsud8br0.png" />{sVenue.venue_place}</span>
                      <span className=''>{sVenue.venue_type}</span>
                    <div className="wedding_date">
                    {sVenue.venue_price}
                    </div>
                  </div>
                )
            })}
            </div> */}
             <div className="app">
          <h1>Wedding halls Listings</h1>
          <div className="card-container">
            {venues.map((venue, index) => (
              <VenueCard key={index} venue={venue} />
            ))}
          </div>
        </div>
        </Card>

       

        </div>
    </div>
   )

}

export default WeddingHalls