import React from 'react'
import './CommonHeader.scss'

export const CommonHeader = ({title,subTitle}) => {
  return (
    <>
      <div className='commonheader mb10'>{title}</div>
      {subTitle && <div className='subtitle'>{subTitle}</div> }   
    </>
  )
}
