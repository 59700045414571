import React from 'react'
import './profile_info.scss'
import { ToastContainer } from 'react-toastify';
import { Form } from 'semantic-ui-react';

const ProfileInfo = () => {

  return (
    <div className='m801_container'>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

      <div className="m800_settingsTitle">Profile Information</div>

      <div className="m801_basics_row">
      
          <img
            className="m801_profilePic"
            src='/img/logo.png'
            alt="img2"
          />

        <button className="m400 plusBlueIcon">
          <img src="/icons/common/plus.svg" className="plusIcon" alt="plus" />
          <span className='cp'>
            Upload New Picture
          </span>
          <input
            className="m400 uploadProfileImg"
            type="file"
            id="file-input"
          />
        </button>

        
       
      </div>
      <Form className='m801_form'>
          <Form.Field>
            <div className="m801 labelIcon">
              <label>Display Name </label>
              <img
                className="m801 filledI"
                src="/icons/settings/filled.svg"
                alt="filled"
              />
            </div>
              <input
                className="activeInput" placeholder="Firstname Lastname"
              
              />
          </Form.Field>
          <Form.Field>
            <div className="m801 labelIcon">
              <label>Email </label>
              <img
                className="m801 filledI"
                src="/icons/settings/filled.svg"
                alt="filled"
              />
            </div>
              <input
                className="m801 activeInput"
              />
          </Form.Field>
          <Form.Field>
            <div className="m801 labelIcon">
              <label>USER ID </label>
              <img
                className="m801 filledI"
                src="/icons/settings/filled.svg"
                alt="filled"
              />
            </div>
              <input
                className="m801 activeInput"
              />
          </Form.Field>
          <Form.Field>
            <div className="m801 labelIcon">
              <label>Location </label>
              <img
                className="m801 filledI"
                src="/icons/settings/filled.svg"
                alt="filled"
              />
            </div>
              <input className="m801 activeInput" />
          </Form.Field>
         
        </Form>




    </div>

  )
}

export default ProfileInfo