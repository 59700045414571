import './App.scss';
import './Styles/Custom-styles/index.scss';
import Authentication from './Routing/Authentications'
import { useCookies } from 'react-cookie';
 
function App() {

  const [cookies] = useCookies(['theme']);
  document.body.className = cookies?.theme ? cookies?.theme : "light_theme" ;
  
  return (
      <Authentication />
  );
}

export default App;
