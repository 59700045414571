import { Icon } from "semantic-ui-react";
import './digital-invitaion.scss'
import { Card, CardBody, CardHeader } from "../../Shared/Card/card";
const DigitalInvitations = () => {
  const moveToInvite = (path) => {
    window.location.replace(path);
  }
 

  return (
    <div>
      
      <Card>
            <CardHeader className={'s400_card_title_blue'} title="Digital invitations"></CardHeader>
            <CardBody>
              <div className= "overview_content digital-invitation-container">
              <div className="overview_card total_normal" onClick={() => moveToInvite('/gunasekhar_raju-weds-haritha')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'> CHIKKILI VARI WEDDING INVITATION</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/guna/both1.PNG" alt="gunashekarraju haritha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Gunasekhar Raju &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Haritha</h4>
                  </div>
                  <div className="wedding_date">
                    16 / 11 / 2024
                  </div>
                </div>
                <div className="overview_card total_normal" onClick={() => moveToInvite('/Jayachandra-weds-Nagamani')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'> YERRAGUNTA VARI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/jn.PNG" alt="sushmitha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Jayachandra Reddy &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Nagamani</h4>
                  </div>
                  <div className="wedding_date">
                    16 / 03 / 2024
                  </div>
                </div>

                <div className="overview_card total_normal" onClick={() => moveToInvite('/JayaSaiKrishna-weds-Sushmitha')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'> UMMANNI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/js.PNG" alt="sushmitha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Jaya SaiKrishna &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Sushmitha</h4>
                  </div>
                  <div className="wedding_date">
                    17 / 12 / 2023
                  </div>
                </div>
                <div className="overview_card total_normal" onClick={() => moveToInvite('https://pabbuleti-wedding-invitation.netlify.app/')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>PABBULETI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/ps.PNG" alt="Swetha a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Prashant &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Swetha</h4>
                  </div>
                  <div className="wedding_date">
                    14 / 12 / 2023
                  </div>
                </div>
                <div className="overview_card total_normal" >
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>MANDLIPALLI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/rd.PNG" alt="Dharani a2zwi" />

                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Rajasekhar reddy &nbsp;
                      <Icon color='white' disabled name='heart' />
                      Dharani</h4>
                  </div>
                  <div className="wedding_date">
                    01 / 11 / 2023
                  </div>
                </div>
                <div className="overview_card total_normal">
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>BOYAPATI</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/vp.jpg" alt="Venkatesh a2zwi" />
                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Venkatesh &nbsp; <Icon color='white' disabled name='heart' /> Pravalika</h4>
                  </div>
                  <div className="wedding_date">
                    10 / 02 / 2022
                  </div>
                </div>
                <div className="overview_card total_normal" onClick={() => moveToInvite('https://swashakha.netlify.app')}>
                  <div className="overviewTitle">
                    <span className='overview_card_titles'>SWASHAKHA</span>
                  </div>
                  <img className="overViewCard_avatar" src="/invites_img/kv.jpeg" alt="karthik a2zwi" />
                  <div className="overviewTitle">
                    <h4 className='overview_card_titles'>Karthik &nbsp; <Icon color='white' disabled name='heart' /> Vaishnavi</h4>
                  </div>
                  <div className="wedding_date">
                    10 / 11 / 2021
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
    </div>
  )

}

export default DigitalInvitations