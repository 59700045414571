import React, { useEffect } from 'react'
import { Link , useLocation} from 'react-router-dom';
import './top_nav.scss';

function TopNav({ Toggle, HandleDarkMode, darkMode }) {
    const location = useLocation()
    const Logout = () => {
    }
    const menuToggle = () => {
        const toggleMenu = document.querySelector('.menu');
        toggleMenu.classList.toggle('active');
    }
    useEffect(() => {
    }, [])


    return (
        <div className='s200_container'>
            <div className="s200_topbar">
                <div className='dfacjc'>
                    <div className="s200_toggle" onClick={Toggle}>
                        <img className='menu_icon' src='/icons/mobile_view/side-menu.svg' alt='menu' height={35} width={35} />
                    </div>
                </div>

                {/* <!-- search --> */}
                {/* <div className="s200_search">
                    <label>
                        <input type="text" placeholder="Search by City" />
                        <img className='icon' src='/icons/common/search.svg' alt='search' />
                    </label>
                </div> */}

                <div className='mobile_view_topnav_title'>
                    {location?.pathname === "/settings/profileInfo" ?
                        "SETTINGS" : location?.pathname.slice(1, location.pathname.length).replace(/-/g, ' ')}
                </div>



                {/* <!-- userimg --> */}

                <div className="role_email_box mr20 df_fdc_g5">
                </div>
                <div className="darkmode_lightmode_top">
                    {darkMode ?
                    <div onClick={() => HandleDarkMode(false)} className='s100_active_modes'>
                        <img className='svg_icon' width={20} src="/icons/sideNav/sun.svg" alt="sun" />
                    </div>
                      :
                    <div onClick={() => HandleDarkMode(true)} className='s100_active_modes' >
                        <img className='svg_icon' width={20} src="/icons/sideNav/moon.svg" alt="moon" />
                    </div>
                    }
                </div>
                <div className='action' hidden>
                    <div className="s200_user" onClick={menuToggle}>
                        <img src= '/img/logo.png' alt='img2' />
                    </div>
                    <div className="menu" hidden>
                        <div className='dfacjc'>
                        </div>
                        <div className='role_box dfacjc'>
                        </div>

                        <ul className='mt10'>
                            <li>
                                <Link to='/settings/profileInfo' onClick={menuToggle}>View Profile</Link>

                            </li>
                            <li>
                                <Link to='/' onClick={Logout} >Logout</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default TopNav