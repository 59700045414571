import React, { useEffect, useState } from 'react'
import "./settings.scss";
import SettingsSideNavData from "./settingsSideNav.json";
import { useLocation } from "react-router-dom";
import Languages from './Languages/languages';
import { CommonHeader } from '../../Shared/Header/CommonHeader';
import ProfileInfo from './Profile_info/profile_info';

const Settings = () => {

  const [switchingValue, setSwitchingValue] = useState('basics')
  const [sideNavData, setSideNavData] = useState([])
  const [activeTab, setActiveTab] = useState({
    profile: true,
    businessDetails: false,
    staffDetails: false,
    domains: false,
    notification: false,
    files: false,
    languages: false,
    policies: false

  })
  const location = useLocation()

  const SwitchTabs = (tabName) => {

    setSwitchingValue(tabName);
    // navigate(tabName)
  }

  const ActiveTab = (tabName) => {
    if (tabName === "profile") {
      setActiveTab({ profile: !activeTab.profile })
    }

    if (tabName === "languages") {
      setActiveTab({ languages: !activeTab.languages })
    }

 

  }

  useEffect(() => {
    setSwitchingValue(location?.pathname);
    
      setSideNavData(SettingsSideNavData)
  }, [location])

  return (
    <div className='m800_container'>
      <div className='m800 header_container'>
        <CommonHeader title={'Settings'} />
      </div>

      <div className='settings_desktop_view'>
        <div className="m800_settings_container">
          <div className="m800_settingsNavigation">
            <ul>
              {sideNavData.map((singleTab, i) => {
                return (
                  <li key={i} className={singleTab.path === switchingValue ? "m800_li_Active" : ""}>
                    <div onClick={() => { SwitchTabs(singleTab.path) }} >
                      <span className="m800_sideTab_title">{singleTab.tabName}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="m800_settingsContent">
            {switchingValue === "/settings/profileInfo" ? <ProfileInfo /> : ''}
            {switchingValue === "/settings/languages" ? <Languages /> : ''}
          </div>
        </div>
      </div>

      <div className='settings_mobile_view'>
        <div className="mobile_settings_container">



        

            <div>


              <div className='mobile_tab' onClick={() => { ActiveTab("profile") }} >
                <div className="m800_mobile_sideTab_title">Profile</div>
                <img className={activeTab?.profile ? "filledI_down" : " filledI_right"} src="/icons/common/Cheveron.svg" alt="Cheveron"></img>
              </div>

              {activeTab?.profile &&
                <div className='MV_switch_content'>
                  <ProfileInfo />
                </div>
              }

              

              <div className='mobile_tab' onClick={() => { ActiveTab("languages") }} >
                <div className="m800_mobile_sideTab_title">Languages</div>
                <img className={activeTab?.languages ? "filledI_down" : " filledI_right"} src="/icons/common/Cheveron.svg" alt="Cheveron"></img>
              </div>

              {activeTab?.languages &&
                <div className='MV_switch_content'>
                  <Languages />
                </div>
              }

            </div>
          
        </div>
      </div>


    </div>
  )
}

export default Settings