import React from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import './card.scss'

const Card = (props) => {
    return (
        <div id={props.id} className={"s400_card " + (props.className !== undefined ? props.className:"") }>
            {props.children}
        </div>
    )
}
const CardBody = (props) => {
    return (
        <div id={props.id} className="s400_card-content">
            <div className="row">
                {props.children}
            </div>
        </div>
    )
}
const CardFooter = (props) => {
    return (
        <div className="s400_footer">
            <div className={"s400_card-action " + props.className ? props.className : ""}>
                {props.children}
            </div>
        </div>
    )
}
const CardHeader = (props) => {
    return (
        <div className="s400_card_header">
            {props.title ? <h4 className={props.className}>{props.title}</h4> : <div />}
            {props.options &&
                <Menu compact className='s400_activity_dropdown grayDropDown'>
                    <Dropdown style={{minWidth : '7em'}} placeholder='Today' selection options={props.options} />
                </Menu>
            }
            {props.option1 &&
                <Menu compact className='s400_activity_dropdown grayDropDown'>
                    <Dropdown style={{minWidth : '7em'}} placeholder='Last 7 days' selection options={props.option1} />
                </Menu>
            }
            {props.children}
        </div>
    )
}

export {
    Card,
    CardBody,
    CardFooter,
    CardHeader
}