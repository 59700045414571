import React, { useEffect } from 'react'
import { Form } from 'semantic-ui-react'

const Languages = () => {
 




  const GetSelectDropDown = () => {
    const optionMenu = document.querySelector(".select-menu")
    const selectBtn = optionMenu.querySelector(".select-btn")
    const options = optionMenu.querySelectorAll(".option")
    const sBtn_text = optionMenu.querySelector(".sBtn-text")
    selectBtn.addEventListener("click", () => optionMenu.classList.toggle("active"));       

    options.forEach(option =>{
        option.addEventListener("click", ()=>{
            let selectedOption = option.querySelector(".option-text").innerText;
            sBtn_text.innerText = selectedOption;
    
            optionMenu.classList.remove("active");
        });
    });
  }



  useEffect(() => {
    GetSelectDropDown()
    
  }, [])


  
  return (
    <div className='m809_container'>
      <div className="m809 m800_settingsTitle">Languages</div>
      <Form>
        <Form.Field className='df_alc_20'>
          <div className="labelIcon">
            <label>Current languages : </label>

          </div>

          

          <div className="select-menu">
            <div className="select-btn">
              <span className="sBtn-text">Languages</span>
              <img
                  className="filledI_down"
                  src="/icons/common/Cheveron.svg"
                  alt="Cheveron"
                />
            </div>

            <ul className="options">
              <li className="option">
                <span className="option-text">English</span>
              </li>
              <li className="option">
                <span className="option-text">US English</span>
              </li>
              {/* <li className="option">
                <i className="bx bxl-linkedin-square" style="color: #0E76A8;"></i>
                <span className="option-text">Linkedin</span>
              </li>
              <li className="option">
                <i className="bx bxl-facebook-circle" style="color: #4267B2;"></i>
                <span className="option-text">Facebook</span>
              </li>
              <li className="option">
                <i className="bx bxl-twitter" style="color: #1DA1F2;"></i>
                <span className="option-text">Twitter</span>
              </li> */}
            </ul>
      </div>

        </Form.Field>
      </Form>

      

      <div className='mt20'>
        <button className='blueBtn' >
          Save Changes
        </button>
      </div>
    </div>
  )
}

export default Languages