// src/Card.js
import React from "react";
import "./card.css";

const VenueCard = ({ venue }) => {
  return (
    <div className="card">
      <div className="card-image-container">
        {venue.isMostPreferred && (
          <div className="badge">Most Preferred</div>
        )}
        <img src={venue.image} alt={venue.name} className="card-image" />
        <div className="image-overlay">
          <span>{venue.imageCount}+</span>
        </div>
      </div>
      <div className="card-content">
        <h4 className="card-title">{venue.name}</h4>
        <p className="location">{venue.location}</p>
        <p className="price">₹{venue.price}</p>
        {/* <div className="rating">
          <span>⭐</span> {venue.rating} <span>{venue.reviews}</span>
        </div> */}
        {/* <div className="info">
          <p>{venue.parking} Car parking</p>
          <p>{venue.capacity} Hall capacity</p>
        </div> */}
        <div className="buttons">
          <button className="availability-button">Check availability</button>
          <button className="contact-button">View contact</button>
        </div>
      </div>
    </div>
  );
};

export default VenueCard;
