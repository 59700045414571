import React, { useEffect, useState } from 'react'
import AdminSideNav from "./adminSideNav.json";
import { Link, useLocation } from 'react-router-dom';
import './side_nav.scss'

function Side_nav({ HandleSideNav, menu, darkMode, HandleDarkMode, Toggle }) {

  const [sideNavData, setSideNavData] = useState([])
  const location = useLocation();

  useEffect(() => {
      setSideNavData(AdminSideNav)
  }, [])


  return (
    <div className="s100_sideNavContainer">
      
      <ul className="s100_sideNavUL">
        <li className='s100_sideNavLI'  onClick={HandleSideNav} >
              <Link className="s100_sideNavAnchor desktop_view_sideTabs" to={'/dashboard'}>
                <div className="s100_iconTitle">
                  <span className="s100_icon">
                    <img className={ menu ? "a2z_wedding_info_logo active" : "a2z_wedding_info_logo"} src={'/img/logo.png'} alt={'logo'} />{" "}
                  </span>
                </div>
              </Link>
              <Link className="s100_sideNavAnchor mobile_view_sideTabs" to={'/dashboard'}>
                <div className="s100_iconTitle" onClick={Toggle}>
                  <span className="s100_icon">
                  <img className={ menu ? "a2z_wedding_info_logo" : "sidenav_icon"} src={'/img/logo.png'} alt={'logo'} />{" "}
                  </span>
                </div>
              </Link>
        </li>
        {sideNavData.map((singleTab, i) => {
          return (
            <li className={location.pathname === singleTab.path ? "s100_sideNavLI active" : "s100_sideNavLI"} onClick={HandleSideNav} key={i}>
              <Link className= {i === sideNavData.length-1 ? "s100_sideNavAnchor desktop_view_sideTabs last_link" :"s100_sideNavAnchor desktop_view_sideTabs "} to={singleTab.path}>
                <div className="s100_iconTitle">
                  <span className="s100_icon">
                    <img className={"sidenav_icon"} src={singleTab.iconPath} alt={singleTab.title} />{" "}
                  </span>
                  <span className="s100_title">{singleTab.title}</span>
                </div>
              </Link>
              <Link className= {i === sideNavData.length-1 ? "s100_sideNavAnchor mobile_view_sideTabs last_link" :"s100_sideNavAnchor mobile_view_sideTabs" }      to={singleTab.path}>
                <div className="s100_iconTitle" onClick={Toggle}>
                  <span className="s100_icon">
                    <img className={"sidenav_icon"} src={singleTab.iconPath} alt={singleTab.title} />{" "}
                  </span>
                  <span className="s100_title">{singleTab.title}</span>
                </div>
              </Link>
            </li>
          );
        })}
        {/* {
          menu ?
            (
              <div>
                <div className='desktop_view_darkmode'>
                  <div className="s100_dark_theme_settings_icons active">
                    <div onClick={() => HandleDarkMode(false)} className={!darkMode ? "s100_active_modes" : "s100_modes"}>
                      <img className='svg_icon' src="/icons/sideNav/sun.svg" alt="sun" />
                    </div>
                    <div onClick={() => HandleDarkMode(true)} className={darkMode ? "s100_active_modes" : "s100_modes"}>
                      <img className='svg_icon' src="/icons/sideNav/moon.svg" alt="moon" />
                    </div>
                  </div>
                </div>


                <div className='mobile_view_darkmode'>
                <div className="s100_dark_theme_settings">
                
                <div className="s100_toggleBtn" onClick={Toggle}>
                  <div onClick={() => HandleDarkMode(false)} className={!darkMode ? "s100_active_btn" : "s100_mode_btn"}>
                    <img src="/icons/sideNav/sun.svg" alt="sun" /> &nbsp;
                    <span className="s100_lightTitle">Light</span>
                  </div>
                  <div onClick={() => HandleDarkMode(true)} className={darkMode ? "s100_active_btn" : "s100_mode_btn"}>
                    <img src="/icons/sideNav/moon.svg" alt="moon" /> &nbsp;
                    <span className="s100_darkTitle">Dark</span>
                  </div>
                </div>
              </div>
                </div>
              </div>

            )
            :
            (
              <div className="s100_dark_theme_settings">
                <div className="s100_toggleBtn">
                  <div onClick={() => HandleDarkMode(false)} className={!darkMode ? "s100_active_btn" : "s100_mode_btn"}>
                    <img src="/icons/sideNav/sun.svg" alt="sun" /> &nbsp;
                    <span className="s100_lightTitle">Light</span>
                  </div>
                  <div onClick={() => HandleDarkMode(true)} className={darkMode ? "s100_active_btn" : "s100_mode_btn"}>
                    <img src="/icons/sideNav/moon.svg" alt="moon" /> &nbsp;
                    <span className="s100_darkTitle">Dark</span>
                  </div>
                </div>
              </div>

            )
        } */}

      </ul>
    </div>
    // </div>
  )
}

export default Side_nav