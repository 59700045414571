import { Card } from "../../Shared/Card/card"

const CarRentals = () => {

   return(
    <div className="m400_container">
        <div className='m400 header_container'>

        <div className='desktop_view_btns'>
          <div className="m400 container_top_btns">
          </div>
        </div>
        <Card>
            <div className="coming_soon">
                Coming Soon....
            </div>
        </Card>

        </div>
    </div>
   )

}

export default CarRentals