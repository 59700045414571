import {Helmet} from "react-helmet";

const PremChandraInvite = () => {
  return (
    <div className="digital-invite-container">
      <Helmet>
                <meta charSet="utf-8" />
                <title>Prem Chandra weds Srilatha Wedding Invitation</title>
                <link rel="icon" href="/invites_img/jayachandra/favicon.png" />
                <link rel="apple-touch-icon" href="/invites_img/jayachandra/favicon.png" />
                <link rel="canonical" href="https://a2zweddinginfo.com/premChandra-weds-srilatha" />
                <meta name="description" content="we request the pleasure of your presence with family &amp; friends on the auspicious occasion of the Prem Chandra &amp; Srilatha's marriage."></meta>
      </Helmet>
      <div className="overview_card total_normal">
       <h3 className="wedding-invite-name">WEDDING INVITATION</h3>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="front pic" src="/invites_img/prem/premchandra.PNG" />
            <img className="flip-img2" alt="card back pic" src="/invites_img/prem/premchandra.PNG" />
          </div>
          <div className="details">
            <img alt="save the date" width={200} src="/invites_img/save-the-date.png" />
            <h1 className="wedding-date">22 <i>August</i> 2024 </h1>
            <a href="/" style={{marginTop:"20px"}}>
             <button className="blink-image invite-btn">Open invite</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PremChandraInvite
