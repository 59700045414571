const RajasekharDharani = () => {
  return (
    <div className="digital-invite-container">
      <div className="overview_card total_normal">
       <h3 className="wedding-invite-name">MANDLIPALLI WEDDING INVITATION</h3>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="front pic" src="/invites_img/rd2.PNG" />
            <img className="flip-img2" alt="card back pic" src="/invites_img/rd.PNG" />
          </div>
          <div className="details">
            <img alt="save the date" width={200} src="/invites_img/save-the-date.png" />
            <h1 className=".wedding-date">1 Nov 2023 </h1>
          </div>
        </div>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="dharani.JPEG" src="/invites_img/dharani.JPEG" />
            <img className="flip-img2" alt="dharani.JPEG" src="/invites_img/dharani.JPEG" />
          </div>
          <div className="details">
            <h1>Bride</h1>
            <h2>Dharani</h2>
            <h3>D/O Subba Reddy </h3>
          </div>
        </div>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="rajasekhar" src="/invites_img/rajasekhar.JPEG" />
            <img className="flip-img2" alt="rajasekhar" src="/invites_img/rajasekhar.JPEG" />
          </div>
          <div className="details">
            <h1>Groom</h1>
            <h2>Rajasekhar Reddy</h2>
            <h3>S/O Reddy </h3>
          </div>
        </div>
      </div>
      <div className="flip-container">
        <div className="flip-card">
          <div className="flip-imgBox">
            <img className="flip-img1" alt="mandapam" src="https://images.jdmagicbox.com/comp/chittoor/v6/9999p8572.8572.180225111230.n5v6/catalogue/sukhibhava-ac-mahal-santhapet-chittoor-kalyana-mandapams-nex3j3hang.jpg?clr=" />
            <img className="flip-img1" alt="mandapam" src="https://images.jdmagicbox.com/comp/chittoor/v6/9999p8572.8572.180225111230.n5v6/catalogue/sukhibhava-ac-mahal-santhapet-chittoor-kalyana-mandapams-nex3j3hang.jpg?clr=" />
          </div>
          <div className="details">
          </div>
        </div>
      </div>
    </div>
  )
}

export default RajasekharDharani